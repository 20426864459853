<template>
  <!-- 原新建表单和编辑表单 现编辑表单  admin/design -->
  <el-container v-loading="loading">
    <div style="width: 100%" class="process-design">
      <div style="font-size: 18px;">
        <div style="display: block;">
          <span style="display: inline-block; width: 18px; height: 18px; background-color: #909399; border-radius: 50%;"></span>
          <span>未开始</span>
        </div>
        <div style="display: block;">
          <span style="display: inline-block; width: 18px; height: 18px; background-color: #409EFF; border-radius: 50%;"></span>
          <span>执行中</span>
        </div>
        <div style="display: block;">
          <span style="display: inline-block; width: 18px; height: 18px; background-color: #067b50; border-radius: 50%;"></span>
          <span>已执行</span>
        </div>
        <div style="display: block;">
          <span style="display: inline-block; width: 18px; height: 18px; background-color: #F56C6C; border-radius: 50%;"></span>
          <span>已拒绝</span>
        </div>
      </div>
      <div class="layout-body">
        <process-design ref="processDesign" :instance-data="instanceData" />
      </div>
    </div>
  </el-container>
</template>

<script>
import {getHisProcess } from "@/api/process";
import ProcessDesign from "../../process/submit/ProcessDesign1";
import { ROOT_PROPS } from "../../admin/layout/process/DefaultNodeProps";

export default {
  name: "FormProcessDesign2",
  props: {
    groupId: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
    instanceData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ProcessDesign
  },
  data() {
    return {
      isNew: true,
      validStep: 0,
      timer: null,
      loading: false,
      activeSelect: "processDesign",
      validVisible: false,
      isSave: false,
      validResult: {},
      validComponents: [
        "processDesign",
      ],
      formData: {},
      deploy: false,
    };
  },
  computed: {
    setup() {
      return this.$store.state.design;
    },
    isSubProcess() {
      const isSub = this.$route.query.isSub == 1;
      this.setup.isSubProc = isSub;
      return isSub;
    },
    isMobilePreview() {
      return this.$isNotEmpty(this.$route.query.mobilePreview);
    },
    errTitle() {
      if (this.validResult.finished && !this.validResult.success) {
        return (
          this.validResult.title + ` (${this.validResult.errs.length}项错误) 😥`
        );
      }
      return this.validResult.title;
    },
    validIcon() {
      if (!this.validResult.finished) {
        return "el-icon-loading";
      } else if (this.validResult.success) {
        return "success";
      } else {
        return "warning";
      }
    },
  },
  created() {
    let formId = this.code;
    //判断传参，决定是新建还是加载原始数据
    this.loadInitFrom();
    if (this.$isNotEmpty(formId)) {
      this.isNew = false;
      this.loadFormInfo(formId);
    }
    let group = this.groupId;
    this.setup.groupId = this.$isNotEmpty(group) ? parseInt(group) : null;
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    getDfFormConfig() {
      return {
        labelPos: "top",
        ruleType: "SIMPLE",
        labelWidth: "",
        rules: [],
        ruleJs:
          "//formData: 表单数据  formMap: 表单字段id -> 字段json配置\r\nfunction doChange(formData, formMap){\r\n\t\r\n}",
      };
    },
    loadFormInfo(formId) {
      getHisProcess(formId, this.version, this.instanceData.sub, this.instanceData.subCode)
        .then((rsp) => {
          let form = { ...rsp.data };
          form.logo = JSON.parse(form.logo || "{}");
          form.settings = JSON.parse(form.settings || "{}");
          form.formItems = JSON.parse(form.formItems || "[]");
          form.modelStyle = form.modelStyle
            ? JSON.parse(form.modelStyle || "")
            : "";
          ///

          form.fieldlistName = form.fieldlistName;
          form.modelNo = form.modelNo ? JSON.parse(form.modelNo || "") : "";

          form.process = JSON.parse(form.process || "{}");
          form.processConfig = JSON.parse(form.processConfig || "{}");
          form.formConfig = form.formConfig
            ? JSON.parse(form.formConfig)
            : this.getDfFormConfig();
          this.$store.commit("loadForm", form);
          this.formData = form
        })
        .catch((err) => {
          // this.loading = false;
          this.$err(err, "获取流程信息失败");
        });
    },
    loadInitFrom() {
      this.$store.commit("loadForm", {
        formId: null,
        formName: "未命名表单",
        logo: {
          icon: "el-icon-eleme",
          background: "#067b50",
        },
        settings: {
          commiter: [],
          admin: [],
          sign: false,
          enableCancel: true,
          notify: {
            types: ["APP"],
            title: "消息通知标题",
          },
        },
        groupId: undefined,
        formItems: [],
        modelStyle: "",
        formConfig: this.getDfFormConfig(),
        processConfig: {
          listener: {
            start: [],
            pass: [],
            refuse: [],
            cancel: [],
          },
        },
        process: {
          id: "root",
          parentId: null,
          type: "ROOT",
          name: "发起人",
          desc: "任何人",
          props: this.$deepCopy(ROOT_PROPS),
          children: {},
        },
        remark: "",
      });
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-body {
  min-width: calc(60vw);
}

/deep/ .el-step {
  .is-success {
    color: @theme-primary;
    border-color: @theme-primary;
  }
}

/deep/ .el-result {
  .icon-success {
    fill: @theme-success;
  }

  .icon-warning {
    fill: @theme-warning;
  }
}

//width: unset !important;
// .process-design {}

.err-info {
  max-height: 180px;
  overflow-y: auto;

  &>div {
    padding: 5px;
    margin: 2px 0;
    // width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242, 242, 242);
  }

  i {
    margin: 0 5px;
  }
}
</style>
