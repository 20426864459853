import request from './request.js'
import { syncRequest } from './request.js'

/**
 *
 * 查询当前用户的指定级别领导
 * @param level 指定级别
 * @param deptId 部门ID
 * @param skipEmpty 是否跳过空部门
 * @returns {Promise<unknown>}
 */
export function getUserLeader(level, deptId, skipEmpty) {
  return request({
    url: `wflow/process/step/leader/level`,
    method: 'get',
    params: { level: level, deptId: deptId, skipEmpty: skipEmpty }
  })
}

/**
 * 查询当前用户的指定级别下的所有领导
 * @param maxLevel 截至级别，为空则直到顶级
 * @param deptId 部门ID
 * @param skipEmpty 是否跳过空部门
 */
export function getUserLeaders(maxLevel, deptId, skipEmpty) {
  return request({
    url: `wflow/process/step/leader/to/level`,
    method: 'get',
    params: { maxLevel: maxLevel, deptId: deptId, skipEmpty: skipEmpty }
  })
}

//卡券-获得券类列表
export function getAllCategory() {
  return request({
    // url: `ticket/category/getAllCategory`,
    url: `ticket/category/getSQAllCategory`,
    method: 'get',
    params: {}
  })
}

//延期的券详情
export function getBnLimitInfo(paramObj) {
  return request({
    // url: `ticket/category/getAllCategory`,
    url: `workbench/getBnLimitInfo`,
    method: 'get',
    params: { ...paramObj }
  })
}

//延期-可以延期的券列表
export function getDelayBnList(paramObj) {
  return request({
    url: `ticket/delay/getBnList`,
    method: 'get',
    params: { ...paramObj }
  })
}

//节假日列表
export function getHolidayList(paramObj) {
  return request({
    url: `/system/holiday`,
    method: 'get',
    params: { ...paramObj }
  })
}
//查询-根据券类获得券品列表
///categoryId分类,brandName券品名称,type 0:否 1：入库/印制 2:调拨直接 3：调拨申请单
//useType 用途属性：1：销售 2:免费 3：工具
export function getBrandByCategoryId(paramObj) {
  return request({
    url: `ticket/brand/getBrandByCategoryId`,
    method: 'get',
    params: { ...paramObj }
  })
}


// 查询-获得组套券品明细列表
export function getBrandCombinationBrandList(paramObj) {
  return request({
    url: '/ticket/brand/getCombinationBrandList/' + paramObj.id,
    method: 'get',
    params: { ...paramObj }
  })
}
//
/**
 * 获取指定系统角色的所有人员
 * @param roles
 */
export function getUsersByRoles(roles) {
  return request({
    url: `wflow/process/step/userByRoles`,
    method: 'post',
    data: roles
  })
}
/**
 * 获取指定系统角色的所有人员
 * @param posts
 */
export function getUsersByPosts(posts) {
  return request({
    url: `wflow/process/step/userByPosts`,
    method: 'post',
    data: posts
  })
}
/**
 * 查询所有部门主管
 * @param deptIds 部门ID
 */
export function getLeaderByDepts(deptIds) {
  return request({
    url: `wflow/process/step/deptLeader`,
    method: 'post',
    data: deptIds
  })
}

/**
 * 获取指定人员是否属于某部门
 * @param userId 该人员ID
 * @param depts 部门 List
 */
export function userInDepts(userId, depts) {
  let result = false
  syncRequest({
    url: `/wflow/process/step/user/${userId}/belong/depts`,
    method: 'POST',
    data: depts,
    type: 'json',
    success: res => {
      result = res
    }
  })
  return result
}

/**
 * 获取指定的多个人员是否属于某些部门
 * @param userIds 该人员ID集合
 * @param depts 部门 List
 */
export function usersInDepts(userIds, depts) {
  let result = false
  syncRequest({
    url: `/wflow/process/step/users/belong/depts`,
    method: 'POST',
    data: {
      sourceIds: userIds,
      targetIds: depts
    },
    type: 'json',
    success: res => {
      result = res
    }
  })
  return result
}

/**
 * 获取指定的多个部门是否属于某些部门子部门
 * @param depts 该部门ID集合
 * @param parents 父部门 List
 */
export function deptsInDepts(depts, parents) {
  let result = false
  syncRequest({
    url: `/wflow/process/step/depts/belong/depts`,
    method: 'POST',
    data: {
      sourceIds: depts,
      targetIds: parents
    },
    type: 'json',
    success: res => {
      result = res
    }
  })
  return result
}

export function getNewVerProcess(code, isSub = false) {
  return request({
    url: `wflow/process/model${isSub ? '/sub' : ''}/${code}`,
    method: 'get'
  })
}

export function saveProcess(params, isSub = false) {
  return request({
    url: `wflow/process/model${isSub ? '/sub' : ''}/save/`,
    method: 'post',
    data: params
  })
}

export function deployProcess(code, isSub = false) {
  return request({
    url: `wflow/process/model${isSub ? '/sub' : ''}/deploy/${code}`,
    method: 'post'
  })
}

export function getCustomPrintConfig(instanceId) {
  return request({
    url: `wflow/process/model/customPrint/${instanceId}`,
    method: 'get'
  })
}

export function delProcessInst(instanceId) {
  return request({
    url: `wflow/process/instance/${instanceId}`,
    method: 'delete'
  })
}

export function startProcess(code, params, unionId) {
  return request({
    url: `wflow/process/start/${code}`,
    method: 'post',
    data: params,
    params: { unionId: unionId }
  })
}

//查询条件节点的所有满足的条件分支
export function getTrueConditions(params) {
  return request({
    url: `/wflow/process/step/conditions/resolve`,
    method: 'post',
    data: params
  })
}

//校验EL表达式
export function validateEl(el) {
  return request({
    url: `/wflow/process/step/el/validate`,
    method: 'get',
    params: { el: el }
  })
}

//出库详情查询
export function getOrderInfo(params) {
  return request({
    url: `/checkOut/getOrderInfo/${params.id}`,
    method: 'get',
    params:{}
  })
}

//退回记录详情查询
export function getRefundOrderInfo(params) {
  return request({
    url: `/checkOut/getRefundOrderInfo/${params.id}`,
    method: 'get',
    params:{}
  })
}

export function getBrandInfo(params) {
  return request({
    url: '/ticket/brand/getBrandInfo/'+params,
    method: 'get'
  })
}

// 获取节假日列表
export function getHoliday(data) {
  return request({
    url: '/system/holiday',
    method: 'get',
    data
  })
}

// 查询-根据instanceid获取调拨id
export function getOrderId(params) {
  return request({
    url: '/ticket/docMove/getOrderId/'+params.instanceId,
    method: 'get',
    params: {
      defName: params.defName
    }
  })
}

// 获得卡券-卡券调拨详细信息
export function docMoveInfoSearch(data) {
  return request({
    url: '/ticket/docMove/'+data.id,
    method: 'get',
    data
  })
}
// 查询评论人是转交人还是办理人
export function getCommentUserType(userId, createTime, taskId){
  return request({
    url: `/wflow/process/progress/comment/type`,
    method: 'get',
    params: {
      userId: userId,
      createTime: createTime,
      taskId: taskId
    }
  })
}

export function getHisProcess(code, version, isSub, subCode){
  return request({
    url: `wflow/process/model/his${isSub ? '/sub':''}/${code}`,
    method: 'get',
    params: {
      version: version, 
      subCode: subCode
    }
  })
}
export default {
  getUserLeader, getUserLeaders, getUsersByRoles, userInDepts, validateEl, getHisProcess,
  getOrderInfo, getRefundOrderInfo, getBrandInfo, getHoliday, getOrderId, docMoveInfoSearch,
  deployProcess, saveProcess, getNewVerProcess, getCustomPrintConfig, getCommentUserType,
  getLeaderByDepts, delProcessInst, usersInDepts, deptsInDepts, getTrueConditions, getUsersByPosts
}
