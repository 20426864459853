<template>
  <el-main style="height: 800px; overflow-y:auto;" ref="design">
    <el-button icon="el-icon-plus" size="small" @click="scale += 10" :disabled="scale >= 150" circle></el-button>
    <span>{{ scale }}%</span>
    <el-button icon="el-icon-minus" size="small" @click="scale -= 10" :disabled="scale <= 40" circle></el-button>
    <div class="design" :style="'transform: scale(' + scale / 100 + ');'">
      <process-tree ref="process-tree" :domCite="domCite" :instance-data="instanceData" />
    </div>
  </el-main>
</template>

<script>
import ProcessTree from '../../admin/layout/process/ProcessTree.vue'
import modelGroupApi from '@/api/modelGroup'

export default {
  name: "ProcessDesign1",
  components: { ProcessTree },
  props: {
    instanceData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      scale: 100,
      domCite: {},
      processDom: null,
      formId: '',
      groups: []
    }
  },
  computed: {
    processListener() {
      const processConfig = this.$store.state.design.processConfig
      if (!processConfig.listener) {
        this.$set(processConfig, 'listener', {
          start: [],
          pass: [],
          refuse: [],
          cancel: []
        })
      }
      return processConfig.listener
    },
  },
  mounted() {
    this.initDragPageMove()
    this.getGroupModels()
    // this.citeModel()
    // this.$store.commit('setShowRight', false)
    this.$store.commit('setProcessData', this.instanceData)
  },
  methods: {
    citeModel() {
      const isSub = this.instanceData.sub
      // 拿formId去获取流程定义信息
      modelGroupApi.getModelById(this.instanceData.formId, 1, this.instanceData.version, isSub, this.instanceData.subCode).then(rsp => {
        this.domCite = rsp.data.process
      }).catch(err => {
        this.$err(err, '获取流程定义异常')
      })
      this.showCiteModel = false
    },
    getGroupModels() {
      modelGroupApi.getGroupModelsConfig().then(rsp => {
        this.groups = rsp.data
        this.groups.forEach(group => {
          group.items.forEach(item => {
            item.logo = JSON.parse(item.logo)
          })
        })
        this.groups = this.groups.filter(group => ![1, 2].includes(group.id))
      }).catch(err => {
        this.$err(err, '获取分组异常')
      })
    },
    addSetup(type) {
      if (!this.processListener[type]) {
        this.$set(this.processListener, type, [])
      }
      this.processListener[type].push({
        actionType: "NONE",
        java: '',
        js: '',
        http: {
          url: '',
          method: 'GET',
          headers: [],
          contentType: 'JSON',
          params: [],
          data: '',
          preHandler: null,
          aftHandler: null
        }
      })
    },
    validate() {
      return this.$refs["process-tree"].validateProcess()
    },
    initDragPageMove() {
      let isDragging = false
      let startX, startY, scrollLeft, scrollTop
      this.processDom = this.$refs.design.$el
      this.processDom.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        scrollLeft = this.processDom.scrollLeft;
        scrollTop = document.documentElement.scrollTop;
      });
      this.processDom.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;
        // 更新滚动位置
        this.processDom.scrollLeft = scrollLeft - deltaX;
        document.documentElement.scrollTop = scrollTop - deltaY;
      });
      this.processDom.addEventListener('mouseup', () => {
        isDragging = false;
        this.processDom.style.cursor = 'default'
      });
    },
  }
}
</script>

<style lang="less" scoped>
.design {
  margin-top: 50px;
  display: flex;
  transform-origin: 0 0;
  transition: transform 0.1s ease;
}

.w-f-title {
  margin-right: 10px;
}

.scale {
  z-index: 999;
  position: absolute;
  top: 40px;
  right: 40px;

  span {
    margin: 0 10px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

.node-config-content {
  padding: 0 20px 20px;
}

/deep/ .el-drawer__body {
  overflow-y: auto;
}

.action-item {
  position: relative;
  background: #F5F6F6;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;

  &>i {
    top: -8px;
    right: -8px;
    background: #F5F6F6;
    position: absolute;
    color: @theme-danger;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
  }
}
</style>
