<template>
  <node :title="config.name" :show-error="showError" :content="content" :error-info="errorInfo"
        @selected="$emit('selected')" @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)"
        placeholder="请设置触发器" :header-bgc="headerBgc" header-icon="el-icon-set-up"/>
</template>

<script>
import Node from './Node'

export default {
  name: "TriggerNode",
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Node},
  data() {
    return {
      showError: false,
      errorInfo: '',
      headerBgc: '#35b881'
    }
  },
  computed:{
    content(){
      if (this.config.props.type === 'WEBHOOK') {
        return `发送${this.config.props.http.method}请求`
      }else {
        return '发送邮件'
      }
    }
  },
  mounted() {
    const arr = this.$store.state.processData.progress
    if (arr != undefined) {
      if (!arr.find(item => item.nodeId == this.config.id)) this.headerBgc = '#909399'
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].nodeId == this.config.id){
          if (arr[i].finishTime && arr[i].result == 'agree') this.headerBgc = '#067b50'
          else if (arr[i].finishTime && arr[i].result == null) this.headerBgc = '#909399'
          else if (arr[i].result == 'refuse') this.headerBgc = '#F56C6C'
          else if (!arr[i].finishTime) this.headerBgc = '#409EFF' 
        }
      }
    }
  },
  methods: {
    //校验数据配置的合法性
    validate(err){
      this.showError = false
      if (this.config.props.type === 'WEBHOOK'){
        if(this.$isNotEmpty(this.config.props.http.url)){
          this.showError = false
        }else {
          this.showError = true
          this.errorInfo = '请设置WEBHOOK的URL地址'
        }
      }else if(this.config.props.type === 'EMAIL'){
        if(!this.$isNotEmpty(this.config.props.email.subject)
            || this.config.props.email.to.length === 0
            || !this.$isNotEmpty(this.config.props.email.content)){
          this.showError = true
          this.errorInfo = '请设置邮件发送配置'
        }else {
          this.showError = false
        }
      }
      if (this.showError){
        err.push(`${this.config.name} 触发动作未设置完善`)
      }
      return !this.showError
    }
  }
}
</script>

<style scoped>

</style>
