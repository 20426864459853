//获取流程结果标签样式
export function getProcTag(result){
  switch (result){
    case 'RUNNING':
      return ''
    case 'COMPLETE':
    case 'PASS':
      return 'success'
    case 'CANCEL':
      return 'info'
    case 'REFUSE':
      return 'danger'
  }
}

export function getTaskResult(task) {
  const result = task.result || task.taskResult;
  const finishTime = task.finishTime || task.taskEndTime
  if (result === 'agree' && task.nodeId !== 'root') {
    return { text: '已同意', type: 'success' };
  } else if (!result && task.nodeId === 'invalid-end') {
    return { text: '已作废', type: 'warning' };
  } else if (!result && task.nodeId === 'to_check') {
    return { text: '办理中', type: 'primary' };
  } else if (!result && task.nodeId === 'is_checked') {
    return { text: '已办理', type: 'primary' };
  } else if (!result && task.nodeId === 'to_back') {
    return { text: '已退回', type: 'primary' };
  } else if (!result && task.nodeId === 'to_confirm') {
    return { text: '确认中', type: 'primary' };
  } else if (!result && task.nodeId === 'is_confirmed') {
    return { text: '已确认', type: 'primary' };
  } else if (result === 'agree' && task.nodeId === 'root') {
    return { text: '提交审批', type: 'success' };
  } else if (result === 'refuse') {
    return { text: '已拒绝', type: 'danger' };
  } else if (result === 'transfer') {
    return { text: '已转交', type: 'primary' };
  } else if (result === 'recall') {
    return { text: '已退回', type: 'warning' };
  } else if (!result && finishTime) {
    return { text: '已取消', type: 'info' };
  } else {
    return { text: '处理中', type: 'primary' };
  }
}
//判断是否为主要业务节点
export function isPrimaryNode(node){
  return node && node.type && !(isBranchNode(node) || isEmptyNode(node));
}
export function isBranchNode(node){
  return node && (node.type === 'CONDITIONS' || node.type === 'CONCURRENTS' || node.type === 'INCLUSIVES');
}
export function isEmptyNode(node){
  return node && (node.type === 'EMPTY')
}
//是分支节点
export function isConditionNode(node){
  return node.type === 'CONDITIONS';
}
//是分支节点
export function isBranchSubNode(node){
  return node && (node.type === 'CONDITION' || node.type === 'CONCURRENT' || node.type === 'INCLUSIVE');
}
export function isConcurrentNode(node){
  return node.type === 'CONCURRENTS'
}

export function isInclusiveNode(node){
  return node.type === 'INCLUSIVES'
}

export function forEachNode(node, callback){
  if (isBranchNode(node)){
    if (callback(node)){return}
    node.branchs.map(branchNode => {
      if (callback(branchNode)){return}
      forEachNode(branchNode.children, callback)
    })
    forEachNode(node.children, callback)
  }else if (isPrimaryNode(node) || isEmptyNode(node) || isBranchSubNode(node)){
    if (callback(node)){return}
    forEachNode(node.children, callback)
  }
}

export default {
  forEachNode, isPrimaryNode, isBranchNode, isEmptyNode,
  isConditionNode, isBranchSubNode, isConcurrentNode, isInclusiveNode
}
